<script lang="ts" setup>
    import {
        BetriebspruefungAbschliessenCell,
        BooleanCell,
        ChangeBemerkungOfBetriebspruefungCell,
        ChangeKontaktOfBetriebsbeauftragterCell,
        EditPruefungsergebnisCell,
        EmailCell,
        TelefonnummerCell,
        TerminAmCell
    } from '@/components'
    import { useForceFileDownload } from '@/composables'
    import { type Betriebspruefung } from '@/js/model'
    import { useBetriebspruefungService } from '@/services'
    import { useAlertStore, useAuthStore, useFachprueferStore } from '@/stores'
    import {
        type FetchParams,
        formatDate,
        formatDateTime,
        itemsPerPageOptions,
        itemsPerPageText,
        loadingText,
        noItemsText,
        type SortItem,
        type TableHeaders
    } from '@/utils'
    import { computed, onBeforeMount, type Ref, ref, watch } from 'vue'

    const alertStore = useAlertStore()
    const authStore = useAuthStore()

    const isAbgeschlossen = ref<boolean | null>(null)
    const isTerminBestaetigt = ref<boolean | null>(null)

    const itemsPerPage = ref<number>(25)
    const headers = ref<TableHeaders>([
        { title: 'Mitglieds-nummer', align: 'start', key: 'mitgliedsnummer' },
        { title: 'Firma', align: 'start', key: 'firma.nameOne' },
        { title: 'Straße', align: 'start', key: 'adresseFachbetrieb.strasse' },
        { title: 'PLZ', align: 'start', key: 'adresseFachbetrieb.postleitzahl' },
        { title: 'Ort', align: 'start', key: 'adresseFachbetrieb.ort' },
        { title: 'Kontakt', align: 'start', key: 'kontakt', sortable: false },
        {
            title: 'Betriebsbeauftragter',
            align: 'start',
            key: 'betriebsbeauftragterId',
            sortable: false
        },
        {
            title: 'Ablauf Urkunde',
            align: 'start',
            key: 'vorherigeZertifizierungGueltigBis',
            sortable: true,
            value: (item) => `${formatDate(item.vorherigeZertifizierungGueltigBis)}`
        },
        {
            title: 'Prüfungstermin',
            align: 'center',
            children: [
                {
                    title: '',
                    align: 'center',
                    key: 'terminAm',
                    sortable: true,
                    value: (item: Betriebspruefung) => `${formatDate(item.terminAm)}`,
                    headerProps: {
                        'data-test': 'th-pruefungstermin'
                    }
                },
                {
                    title: 'angefragt?',
                    align: 'center',
                    key: 'isTerminAngefragt',
                    sortable: true
                },
                {
                    title: 'bestätigt?',
                    align: 'center',
                    key: 'isTerminBestaetigt',
                    sortable: true
                }
            ]
        },
        {
            title: 'Prüfungsergebnis',
            align: 'center',
            key: 'pruefungsergebnis.mangel.mangel',
            value: (item: Betriebspruefung) => `${item.pruefungsergebnis?.mangel?.mangel}`
        },
        // {
        //     title: 'Mangel letzte Prüfung',
        //     align: 'start',
        //     key: 'mangelDerPruefung.mangelKey',
        //     value: (item) => item.mangelDerPruefung.mangel
        // },
        { title: 'Bemerkungen', align: 'start', key: 'bemerkung', sortable: false },
        {
            title: 'Letzte Änderung',
            align: 'start',
            key: 'updatedAt',
            value: (item) => `${formatDateTime(item.updatedAt)}`
        }
    ])

    const filteredHeaders = computed(() => {
        const isAllowedToAccessBemerkung = authStore.hasRole('Fachpruefer')
        if (headers.value) {
            return headers.value.filter(
                // bemerkung is only visible for Fachpruefer
                (header) => header.key !== 'bemerkung' || isAllowedToAccessBemerkung
            )
        }
        return []
    })

    const serverItems: Ref<Betriebspruefung[]> = ref([])
    const tableOptions = ref<{ sortBy: SortItem[]; page?: number; itemsPerPage?: number }>({
        sortBy: []
    })
    const isExportLoading = ref<boolean>(false)
    const exportFetchParams = ref<FetchParams>({})
    const loading = ref<boolean>(true)
    const totalItems = ref<number>(0)
    const mitgliedsnummer = ref<string | null>(null)
    const search = ref<string>('')
    const selectedYear = ref(new Date().getFullYear())

    const betriebspruefungService = useBetriebspruefungService()
    const fachprueferStore = useFachprueferStore()

    const dateList = () => {
        const currentYear = new Date().getFullYear()

        const lastYear = new Date(currentYear - 1, 0, 1).getFullYear()
        const nextYear = new Date(currentYear + 1, 0, 1).getFullYear()

        return [lastYear, currentYear, nextYear]
    }

    const onItemChanged = (newItem: Betriebspruefung) => {
        const index = serverItems.value.findIndex((item) => item['@id'] === newItem['@id'])

        if (index !== -1) {
            serverItems.value.splice(index, 1, newItem)
        } else {
            const message = 'Beim Ändern der Betriebsprüfung ist etwas schiefgelaufen.'
            alertStore.error(message)
            throw new Error(message)
        }
    }

    const onBetriebsbeauftragterChanged = () => {
        refreshTable()
    }

    const getFileName = () => {
        let title = `betriebsprüfungen_export.csv`

        if (null !== isAbgeschlossen.value) {
            title = `abgeschlossen_${isAbgeschlossen.value ? 'ja' : 'nein'}_${title}`
        }
        if (isTerminBestaetigt.value) {
            title = `terminiert_${isTerminBestaetigt.value ? 'ja' : 'nein'}_${title}`
        }
        if (mitgliedsnummer.value) {
            title = `Mitgliedsnummer_${mitgliedsnummer.value}_${title}`
        }
        if (selectedYear.value) {
            title = `Termin_in_${selectedYear.value}_${title}`
        }

        // will/can be used for date range filters which are going to be implemented next
        // const startDate = dayjs(filter?.startTime?.startDate);
        // const endDate = dayjs(filter?.startTime?.endDate);
        // if (startDate.isValid() && endDate.isValid()) {
        //     const formattedStartDate = startDate.format('YYYYMMDD');
        //     const formattedEndDate = endDate.format('YYYYMMDD');
        //     if (formattedStartDate === formattedEndDate) {
        //         title = `${formattedStartDate}_${title}`;
        //     } else {
        //         title = `${formattedStartDate}-${formattedEndDate}_${title}`;
        //     }
        // }

        return title
    }

    const exportBetriebspruefungen = async () => {
        isExportLoading.value = true
        exportFetchParams.value.itemsPerPage = 100000
        const response = await betriebspruefungService.exportBetriebspruefungen(
            exportFetchParams.value
        )
        useForceFileDownload(response, getFileName())
        isExportLoading.value = false
    }

    const loadItems = async ({
        page,
        itemsPerPage,
        sortBy
    }: FetchParams & {
        sortBy: SortItem[]
    }) => {
        loading.value = true
        tableOptions.value = {
            page,
            itemsPerPage,
            sortBy
        }
        let data: FetchParams = {
            page,
            itemsPerPage,
            isAbgeschlossen: isAbgeschlossen.value,
            isTerminBestaetigt: isTerminBestaetigt.value,
            mitgliedsnummer: mitgliedsnummer.value
        }

        if (selectedYear.value) {
            data['vorherigeZertifizierungGueltigBis[after]'] = new Date(
                selectedYear?.value,
                0,
                1
            )?.toISOString()
            data['vorherigeZertifizierungGueltigBis[before]'] = new Date(
                selectedYear?.value,
                11,
                31
            )?.toISOString()
        }

        if (sortBy[0]) {
            sortBy.forEach((val: SortItem) => (data[`order[${val.key}]`] = val.order))
        }

        exportFetchParams.value = data

        try {
            const result = await betriebspruefungService.getAllBetriebspruefungen(data)
            const items = result['hydra:member']
            const total = result['hydra:totalItems']

            serverItems.value = items
            totalItems.value = total ?? 0
            loading.value = false
        } catch (e: unknown) {
            loading.value = false
        }
    }

    function refreshTable() {
        search.value = String(Date.now())
    }

    watch([mitgliedsnummer, selectedYear, isAbgeschlossen, isTerminBestaetigt], () => {
        refreshTable()
    })

    onBeforeMount(async () => {
        await Promise.all([fachprueferStore.fetchFachpruefende()])
    })
</script>

<template>
    <v-row class="pb-4">
        <v-col cols="12" md="3">
            <v-text-field
                v-model="mitgliedsnummer"
                density="compact"
                placeholder="Nach Mitgliedsnummer suchen..."
                append-inner-icon="mdi-magnify"
                hide-details
                clearable
            ></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
            <v-btn-toggle color="primary" v-model="selectedYear" group density="compact">
                <v-btn
                    v-for="date in dateList()"
                    :key="date"
                    :value="date"
                    :data-test="`filter-year-${date}`"
                    >{{ date }}</v-btn
                >
            </v-btn-toggle>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="3" xl="2">
            <v-btn-toggle v-model="isAbgeschlossen" color="primary" group density="compact">
                <v-btn value="1">Abgeschlossen</v-btn>
                <v-btn value="0">Offen</v-btn>
            </v-btn-toggle>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="3" xl="2">
            <v-btn-toggle v-model="isTerminBestaetigt" color="primary" group density="compact">
                <v-btn value="1">Terminiert</v-btn>
                <v-btn value="0">Unbestätigt</v-btn>
            </v-btn-toggle>
        </v-col>
    </v-row>
    <v-data-table-server
        v-model:items-per-page="itemsPerPage"
        :headers="filteredHeaders"
        :items="serverItems"
        :items-length="totalItems"
        :items-per-page-options="itemsPerPageOptions"
        :items-per-page-text="itemsPerPageText"
        :loading="loading"
        :search="search"
        item-value="name"
        :no-data-text="noItemsText"
        :loading-text="loadingText"
        multi-sort
        @update:options="loadItems"
        density="compact"
        fixed-header
        fixed-footer
        hover
        height="75vh"
    >
        <template v-slot:item.firma.nameOne="{ item }">
            {{ item.firma.name }}
        </template>
        <template v-slot:item.pruefungsergebnis.mangel.mangel="{ item }">
            <EditPruefungsergebnisCell
                :betriebspruefung="item"
                @item-changed="onItemChanged"
                :key="item['@id']"
            />
            <BetriebspruefungAbschliessenCell
                :betriebspruefung="item"
                @item-changed="onItemChanged"
                :key="item['@id']"
            />
        </template>
        <template v-slot:item.bemerkung="{ item }">
            <ChangeBemerkungOfBetriebspruefungCell
                :betriebspruefung="item"
                @item-changed="onItemChanged"
                :key="item['@id']"
            />
        </template>
        <template v-slot:item.terminAm="{ item }">
            <TerminAmCell
                :betriebspruefung="item"
                @item-changed="onItemChanged"
                :key="item['@id']"
            />
        </template>
        <template v-slot:item.isTerminAngefragt="{ item }">
            <BooleanCell v-if="!item.isAbgeschlossen" :is-enabled="item.isTerminAngefragt" />
        </template>
        <template v-slot:item.isTerminBestaetigt="{ item }">
            <BooleanCell v-if="!item.isAbgeschlossen" :is-enabled="item.isTerminBestaetigt" />
        </template>
        <template v-slot:item.betriebsbeauftragterId="{ item }">
            <ChangeKontaktOfBetriebsbeauftragterCell
                v-if="item.betriebsbeauftragterId"
                :betriebsbeauftragterId="item.betriebsbeauftragterId"
                :betriebspruefung="item"
                :key="item.betriebsbeauftragterId"
                @betriebsbeauftragter-changed="onBetriebsbeauftragterChanged"
            />
        </template>
        <template v-slot:item.kontakt="{ item }">
            <template v-for="(email, key) in item?.kontaktBetriebsbeauftragter?.email" :key="key">
                <div class="d-flex ga-1" title="Betriebsbeauftragter">
                    <v-chip color="primary" variant="tonal" size="x-small">B</v-chip>
                    <EmailCell :email="email" />
                </div>
            </template>
            <template v-for="(email, key) in item?.kontaktFachbetrieb?.email" :key="key">
                <div class="d-flex ga-1" title="Fachbetrieb">
                    <v-chip color="primary" variant="tonal" size="x-small">FB</v-chip>
                    <EmailCell :email="email" />
                </div>
            </template>
            <template
                v-for="(telefonnummer, key) in item?.kontaktBetriebsbeauftragter?.telefon"
                :key="key"
            >
                <div class="d-flex ga-1" title="Betriebsbeauftragter">
                    <v-chip color="primary" variant="tonal" size="x-small">B</v-chip>
                    <TelefonnummerCell :telefonnummer="telefonnummer" />
                </div>
            </template>
            <template v-for="(mobil, key) in item?.kontaktBetriebsbeauftragter?.mobil" :key="key">
                <div class="d-flex ga-1" title="Betriebsbeauftragter">
                    <v-chip color="primary" variant="tonal" size="x-small">B</v-chip>
                    <TelefonnummerCell :telefonnummer="mobil" />
                </div>
            </template>
            <template v-for="(telefonnummer, key) in item?.kontaktFachbetrieb?.telefon" :key="key">
                <div class="d-flex ga-1" title="Fachbetrieb">
                    <v-chip color="primary" variant="tonal" size="x-small">FB</v-chip>
                    <TelefonnummerCell :telefonnummer="telefonnummer" />
                </div>
            </template>
            <template v-for="(mobil, key) in item?.kontaktFachbetrieb?.mobil" :key="key">
                <div class="d-flex ga-1" title="Fachbetrieb">
                    <v-chip color="primary" variant="tonal" size="x-small">FB</v-chip>
                    <TelefonnummerCell :telefonnummer="mobil" />
                </div>
            </template>
        </template>
    </v-data-table-server>
    <v-btn
        small
        color="primary"
        block
        :disabled="loading || isExportLoading || totalItems === 0"
        @click="exportBetriebspruefungen"
        >{{ totalItems }} Betriebsprüfung{{ totalItems === 1 ? '' : 'en' }} als .csv-Datei
        exportieren
        <v-icon v-if="!isExportLoading" icon="mdi-download" />
        <v-progress-circular v-else indeterminate size="x-small" class="ml-2" />
    </v-btn>
</template>

<style scoped>
    :deep(.v-select .v-field-label) {
        color: grey;
        opacity: 1;
    }

    :deep(.v-input input::placeholder) {
        color: grey;
        opacity: 1;
    }
</style>
