import dayjs from 'dayjs'

export const formatDate = (dateString: Date | string | null) => {
    return dateString ? dayjs(dateString).format('DD.MM.YYYY') : ''
}
export const formatDateTime = (dateString: Date | string | null) => {
    return dateString ? dayjs(dateString).format('DD.MM.YYYY HH:mm:ss') : ''
}
export const formatDateTimeNoSeconds = (dateString: Date | string | null) => {
    return dateString ? dayjs(dateString).format('DD.MM.YYYY HH:mm') : ''
}
